import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"mt-2 pa-3"},[_c(VCol,{attrs:{"cols":"12","md":"5"}},[_c('h4',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Distribuir valores automaticamente ")]),_c(VBtn,{attrs:{"disabled":_vm.configMetasIndividuais &&
        _vm.configMetasIndividuais.colaboradoresSelected.length > 0
          ? false
          : true,"small":"","color":"info"},on:{"click":function($event){return _vm.distribuirValores()}}},[_vm._v(" Distribuir valores ")])],1),_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c(VAutocomplete,{attrs:{"items":_vm.listaGestores,"outlined":"","color":"blue-grey lighten-2","label":"Gestores","item-value":"id","item-text":"nome","multiple":"","return-object":""},on:{"change":function($event){return _vm.validarColaboradores()}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',[_c(VListItem,{staticClass:"my-2",attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(data.item.nome))]),_c(VListItemSubtitle,[_c('strong',[_vm._v("Cargo:")]),_vm._v(" "+_vm._s(data.item.cargo)+" "),_c('strong',[_vm._v("Setor:")]),_vm._v(" "+_vm._s(data.item.setor))])],1)],1)],1)]}}]),model:{value:(_vm.configMetasIndividuais.gestoresSelected),callback:function ($$v) {_vm.$set(_vm.configMetasIndividuais, "gestoresSelected", $$v)},expression:"configMetasIndividuais.gestoresSelected"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headersDatatable,"items":_vm.configMetasIndividuais.colaboradoresSelected,"items-per-page":10,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text': 'Linhas por página',
      },"mobile-breakpoint":"0","no-data-text":"Nenhum registro encontrado"},scopedSlots:_vm._u([_vm._l((_vm.headersDatatable),function(h){return {key:("header." + (h.value)),fn:function(ref){
      var header = ref.header;
return [_c('span',{key:h.text},[_vm._v(" "+_vm._s(h.text)+" ")])]}}}),{key:"item.colaborador",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"},[_vm._v(" "+_vm._s(item.nome)+" ")]),_c('small',[_vm._v(_vm._s(item.cargo))]),_c('small',[_vm._v(_vm._s(item.setor))])])])]}},_vm._l((_vm.headersDatatable),function(j,i){return {key:("item." + (j.value)),fn:function(ref){
      var item = ref.item;
return [_c('tr',{key:i,staticClass:"d-flex align-center"},[(i == 0)?_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"},[_vm._v(" "+_vm._s(item.nome)+" ")]),_c('small',[_vm._v(_vm._s(item.cargo))]),_c('small',[_vm._v(_vm._s(item.setor))])]):_c('span',[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.unidadesMedidas[_vm.configuracao.unidade].mask),expression:"unidadesMedidas[configuracao.unidade].mask"}],staticClass:"mt-5 mb-2 pa-0",attrs:{"prefix":_vm.unidadesMedidas[_vm.configuracao.unidade].prefix,"outlined":"","rules":[_vm.validators.required],"hide-details":"auto","dense":"","label":("Obj. " + i)},model:{value:(item.meta[("" + (i - 1))].objetivo),callback:function ($$v) {_vm.$set(item.meta[("" + (i - 1))], "objetivo", $$v)},expression:"item.meta[`${i - 1}`].objetivo"}})],1)])]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }