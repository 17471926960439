<template>
  <v-row class="mt-2 pa-3">
    <v-col cols="12" md="5">
      <h4 class="text-subtitle-1 font-weight-medium">
        Distribuir valores automaticamente
      </h4>
      <v-btn
        :disabled="
          configMetasIndividuais &&
          configMetasIndividuais.colaboradoresSelected.length > 0
            ? false
            : true
        "
        small
        color="info"
        @click="distribuirValores()"
      >
        Distribuir valores
      </v-btn>
    </v-col>
    <v-col cols="12" md="7">
      <v-autocomplete
        v-model="configMetasIndividuais.gestoresSelected"
        :items="listaGestores"
        outlined
        color="blue-grey lighten-2"
        label="Gestores"
        item-value="id"
        item-text="nome"
        multiple
        return-object
        @change="validarColaboradores()"
      >
        <template v-slot:item="data">
          <div>
            <v-list-item class="my-2" two-line>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                <v-list-item-subtitle>
                  <strong>Cargo:</strong> {{ data.item.cargo }}
                  <strong>Setor:</strong>
                  {{ data.item.setor }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headersDatatable"
        :items="configMetasIndividuais.colaboradoresSelected"
        :items-per-page="10"
        class="table-kitchen-sink"
        items-per-page-text="Linhas por página"
        no-results-text="Nenhum registro encontrado"
        loading-text="Carregando... Por favor, espere"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text': 'Linhas por página',
        }"
        mobile-breakpoint="0"
        no-data-text="Nenhum registro encontrado"
      >
        <template
          v-for="h in headersDatatable"
          v-slot:[`header.${h.value}`]="{ header }"
        >
          <span :key="h.text">
            {{ h.text }}
          </span>
        </template>
        <template v-slot:[`item.colaborador`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.nome }}
              </span>
              <small>{{ item.cargo }}</small>
              <small>{{ item.setor }}</small>
            </div>
          </div>
        </template>
        <template
          v-for="(j, i) in headersDatatable"
          v-slot:[`item.${j.value}`]="{ item }"
        >
          <tr class="d-flex align-center" :key="i">
            <div class="d-flex flex-column ms-3" v-if="i == 0">
              <span
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.nome }}
              </span>
              <small>{{ item.cargo }}</small>
              <small>{{ item.setor }}</small>
            </div>
            <span v-else>
              <v-text-field
                v-model="item.meta[`${i - 1}`].objetivo"
                v-mask="unidadesMedidas[configuracao.unidade].mask"
                :prefix="unidadesMedidas[configuracao.unidade].prefix"
                outlined
                :rules="[validators.required]"
                hide-details="auto"
                dense
                class="mt-5 mb-2 pa-0"
                :label="`Obj. ${i}`"
              ></v-text-field>
            </span>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import { required, requiredNumeber } from "@core/utils/validation";
import { onMounted, ref, computed } from "@vue/composition-api";
import Grid from "@/components/Grid.vue";

import { unMaskNumber, processFloat } from "@/helpers";

export default {
  name: "meta-individuais-Component",
  components: { Grid },
  setup(_, { emit }) {
    // LifeCycle
    onMounted(() => {
      processaHeaderDataTable();
    });

    // Computed
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const configuracao = computed(
      () => store.getters["avdTecnicas/getConfiguracao"]
    );
    const distribuicaoValores = computed(
      () => store.getters["avdTecnicas/getDistribuicaoValores"]
    );
    const headersDatatable = ref([]);
    const configMetasIndividuais = computed({
      get() {
        return store.getters["avdTecnicas/getConfigMetasIndividuais"];
      },
      set(value) {
        store.dispatch("avdTecnicas/setConfigMetasIndividuais", value);
      },
    });
    const colaboradores = computed(
      () => store.getters["avdColaboradores/getColaboradores"]
    );
    const listaGestores = computed(() => {
      if (colaboradores.length < 1) return [];

      const arrayGestoresFiltred = colaboradores.value.filter(
        (el) => el.gestor == 1
      );

      return arrayGestoresFiltred;
    });
    const mesesPeriodicidade = computed(
      () => store.getters["avdTecnicas/getMesesPeriodicidade"]
    );

    /**
     * TODO
     *
     * - Falta modificar a parte em que ao selecionar o gestor automaticamente será processado e retornado os colaboradores do mesmo na tabela.
     */

    // Functions
    function processaHeaderDataTable() {
      const arrayHeaderDataTable = [
        {
          text: "Colaborador",
          value: "colaborador",
          sortable: false,
          width: "150px",
          class: "header theadcol header-fixo",
        },
      ];

      let count = 1;

      while (count <= mesesPeriodicidade.value) {
        const header = {
          text: `Mês ${count}`,
          value: `desdobramento-${count}`,
          sortable: true,
          width: "150px",
          class: "header headcol bordaCabecalho",
        };

        arrayHeaderDataTable.push(header);

        count++;
      }

      headersDatatable.value = arrayHeaderDataTable;
    }
    function processaColaboradores() {
      configMetasIndividuais.value.colaboradoresSelected = [];

      let colaboradoresSelecionados = [];
      for (let gestor of configMetasIndividuais.value.gestoresSelected) {
        const colaboradoresFiltrados = colaboradores.value.filter(
          (el) => el.id_gestor == gestor.id
        );

        colaboradoresSelecionados.push(colaboradoresFiltrados);
      }

      for (let colaboradores of colaboradoresSelecionados) {
        configMetasIndividuais.value.colaboradoresSelected.push(
          ...colaboradores
        );
      }

      configMetasIndividuais.value.colaboradoresSelected.map((el) => {
        if (!el.meta || el.meta.length < 1) {
          el.meta = [];

          let count = 1;
          while (count <= mesesPeriodicidade.value) {
            const meta = { value: `desdobramento-${count}`, objetivo: "" };

            el.meta.push(meta);

            count++;
          }
        }
      });
    }
    function validarColaboradores() {
      // Limpa arrays em caso de nenhum valor esteja selecionado
      if (
        configMetasIndividuais.value.gestoresSelected.length < 1 ||
        !mesesPeriodicidade.value
      ) {
        configMetasIndividuais.value.colaboradoresSelected = [];
      }

      processaColaboradores();
    }
    function distribuirValores() {
      if (
        configMetasIndividuais.value &&
        configMetasIndividuais.value.colaboradoresSelected.length < 1
      ) {
        store.dispatch("snackBarModule/openSnackBar", {
          color: "danger",
          timeout: 3000,
          text: "Não há colaboradores para distribuir o valor",
        });

        return;
      }

      if (distribuicaoValores.value.findIndex((el) => el.value == "") != -1) {
        store.dispatch("snackBarModule/openSnackBar", {
          color: "danger",
          timeout: 3000,
          text: "Distribuição de valores deve estar totalmente preenchida!",
        });

        return;
      }

      configMetasIndividuais.value.colaboradoresSelected.map((el) => {
        for (let i = 0; i < distribuicaoValores.value.length; i++) {
          const distribuicaoConvertido = unMaskNumber(
            distribuicaoValores.value[i].value,
            configuracao.value.unidade
          );

          const distribuicaoDividido =
            distribuicaoConvertido /
            configMetasIndividuais.value.colaboradoresSelected.length;

          const objetivoProcessado = processFloat(
            distribuicaoDividido,
            configuracao.value.unidade
          );

          el.meta[i].objetivo = objetivoProcessado;
        }
      });

      this.$forceUpdate();
    }

    // variables
    const unidadesMedidas = {
      RE: {
        mask: [
          "#,##",
          "##,##",
          "###,###",
          "#.###,##",
          "##.###,##",
          "###.###,##",
          "#.###.###,##",
        ],
        prefix: "R$",
      },
      DO: {
        mask: [
          "#.##",
          "##.##",
          "###.###",
          "#,###.##",
          "##,###.##",
          "###,###.##",
          "#,###,###.##",
        ],
        prefix: "US$",
      },
      UN: {
        mask: ["#", "##", "###", "#.###", "##.###", "###.###", "#.###.###"],
        prefix: "UN",
      },
      PO: {
        mask: ["#,##", "##,##", "###,##"],
        prefix: "%",
      },
    };

    return {
      validators: {
        required,
        requiredNumeber,
      },

      //Function
      validarColaboradores,
      distribuirValores,

      // Computed
      colaboradores,
      listaGestores,
      headersDatatable,
      configuracao,
      configMetasIndividuais,
      mesesPeriodicidade,
      distribuicaoValores,

      // Variables
      unidadesMedidas,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .header {
    background-color: #fff !important;
    box-shadow: 0 0 0 1px #fff;
  }

  .bordaCabecalho {
    background-color: #f6f6f6 !important;
  }

  .headcol {
    left: 0;
    top: auto;
    z-index: 0;
  }

  .coluna-vazia {
    width: 1em !important;
    left: 0;
    top: auto;
    z-index: 0;
  }

  .coluna-fixa {
    background-color: #f6f6f6;
    position: sticky;
    width: 5em;
    left: 0;
    z-index: 0;
  }

  .coluna-secundaria-fixa {
    background-color: #f6f6f6;
    position: sticky;
    width: 5em;
    left: 170px;
    z-index: 0;
  }

  .header-fixo {
    position: sticky;
    width: 5em;
    left: 0;
    z-index: 0;
  }

  .header-secundaria-fixo {
    position: sticky;
    width: 5em;
    left: 170px;
    z-index: 0;
  }

  .fixedcol {
    position: sticky;
    width: 200px;
    right: 0;
    text-align: center !important;
  }
}
</style>
